import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { FuncArea, FuncSection, LogoSection } from "component/page/item";
import { Tap } from "component/basic/tap";
import { moveScroll } from "js/function";
import FixedSection from "component/basic/fixedBox";

const FuncAbout = (props) => {
    const navigate = useNavigate();
    const [tap,setTap] =  useState(null);
   
    const funcData = [
        {
            id:"func_0",
            name:"워크플로우",
            data:[
                {
                    title:"전자결제 시스템으로 언제, 어디서나 신청 및 결제를 한번에!",
                    text:"아직까지 추가수당/연차/재직증명서 신청 등을<br/>종이 문서나 구두로 하고 계신가요?<br/><br/>이제 웨이브원 전자결제 시스템 하나만으로<br/>모든 신청 및 결제를 한번에 해결하세요",
                    img:"func_1.png",
                    subList:[]
                },
                {
                    addclass:"reverse",
                    title:"신청과 결제, 이력관리에서 급여대장 연동까지 웨이브원으로 한번에 해결",
                    text:"신청과 결제에 필요한 시간을 획기적으로 단축할 수 있으며,<br/>신청된 내역의 이력관리는 물론 승인된 내역은 자동으로 급여대장에 반영되어<br/><b>업무효율성</b>을 크게 높일 수 있습니다.<br/><br/>또한 재직증명서 등 원하는 문서를 발급받고<br/>모바일로 전송받는 기능까지 한번에!",
                    img:"func_2.png",
                    subList:[]
                }
            ]
        },
        {
            id:"func_1",
            name:"직원관리 및 전자계약",
            data:[
                {
                    title:"웨이브원의 전자계약이 특별한 이유",
                    text:"신청과 결제, 이력관리에서 급여대장 연동까지 <b>웨이브원으로 한번에 해결</b>",
                    img:"func_3.png",
                    subList:[
                        "회사에 필요한 다양한 계약서가 모두 전자계약 서식.",
                        "포괄임금AI를 통해 회사 운영시간에 적합한 포괄임금계약서를 자동으로 산출.",
                        "근로기준법 등 현행법에 맞게 전문가가 검토한 서식을 사용.",
                        "임금계약서 상 내용이 자동으로 매월 급여대장에 반영."
                    ]
                },
                {
                    addclass:"reverse",
                    title:"회사와 직원의 근로계약도 이제는 온라인 전자계약으로 한번에!",
                    text:"작성도 관리도 번거로운 종이계약서<br/>이젠 웨이브원의 전자계약으로 바꿔보세요!",
                    img:"func_4.png",
                    subList:[]
                },
                {
                    title:"효율적인 직원 인사정보 관리",
                    text:"근무중인 직원 및 퇴사한 직원의 이력까지 한 곳에서 관리하는 인사정보시스템!<br/>입사일, 부서, 직책, 계약형태, 각종 인별 계약서, 급여, 퇴사일 등의<br/>모든 정보를 웨이브원 직원관리 리스트에서 한번에 관리가능",
                    img:"func_5.png",
                    subList:[]
                }
            ]
        },
        {
            id:"func_2",
            name:"근태관리",
            data:[
                {
                    title:"웨이브원의 근태관리가 편리한 이유",
                    text:"출•퇴근관리에서 근무 스케줄링, 급여대장 연동까지 웨이브원으로 한번에 해결",
                    img:"func_6.png",
                    subList:[
                        "별도 프로그램 구매없이 WIFI 인증 방식으로 직원의 출퇴근 인증을 스마트폰으로 간편하게.",
                        "병원 등 스케줄링 근무를 해야하는 곳의 자동 스케줄링 기능(AI 휴무설정).",
                        "캘린더에서 실시간 확인 가능한 직원별 출퇴근현황.",
                        "근태기록이 자동으로 매월 급여대장에 반영."
                    ]
                },
                {
                    addclass:"reverse",
                    title:"정확하고 편리한 웨이브원의 출퇴근 및 근무 스케줄 관리",
                    text:"아직도 출퇴근 및 근무 스케줄 관리를 수기나 엑셀 파일로 하고 계신가요?<br/>웨이브원의 근태관리 시스템은 출퇴근 및 근무스케줄 관리를<br/>하나의 프로그램으로 편리하게 관리해 줍니다.<br/>불편한 근태관리 안녕 ~",
                    img:"func_7.png",
                    subList:[]
                }
            ]
        },
        {
            id:"func_3",
            name:"연차 • 휴가 관리",
            data:[
                {
                    title:"웨이브원의 연차•휴가관리가 특별한 이유",
                    text:"출•연차 부여와 사용, 촉진제도 및 급여대장 연동까지 웨이브원으로 한번에 해결",
                    img:"func_8.png",
                    subList:[
                        "연차 부여와 소멸, 연차 촉진제도까지 근로기준법에 맞는 연차 관리.",
                        "연차의 부여와 소멸, 촉진제도가 한번의 설정으로 자동 관리.",
                        "직원별 연차 및 휴가 사용 이력을 한눈에 확인.",
                        "연차 및 휴가 기록이 자동으로 매월 급여대장에 반영."
                    ]
                },
                {
                    addclass:"reverse",
                    title:"직원 연차와 휴가 관리를 수기나 엑셀로 하고 계신가요?",
                    text:"이제 웨이브원 연차 • 휴가관리 시스템을 통해 한번에 효율적으로 관리하세요.<br/>연차부여에서 연차사용 및 휴가신청, 이력확인까지 한눈에!",
                    img:"func_9.png",
                    subList:[]
                }
            ]
        },
        {
            id:"func_4",
            name:"급여 관리",
            data:[
                {
                    title:"웨이브원의 급여관리가 특별한 이유",
                    text:"매월 급여대장 작성, 급여지급, 임금명세서 발송 및 퇴직금 지급까지 웨이브원으로 한번에 해결",
                    img:"func_11.png",
                    subList:[
                        "직원의 근태/연차관리 등 입사-근무-퇴사까지의 모든 정보가 자동으로 급여대장에 반영.",
                        "세전금액은 물론 원천세 및 4대보험까지 반영된 세후지급금액 자동산출.",
                        "매월 근로소득은 물론 사업/기타소득 및 일용직 근로소득 지급자 내역까지 한눈에 확인.",
                        "급여관련 법과 세율이 변경될 경우 프로그램에 자동 업데이트.",
                        "급여마감시 임금명세서를 직원의 이메일 및 앱으로 자동발송.",
                        "퇴사하는 직원의 실제 세전/세후 퇴직금까지 웨이브원에서 확인가능."
                    ]
                },
                {
                    addclass:"reverse",
                    title:"아직도 매월 급여대장을 직접 작성하고 계신가요?<br/>입사와 퇴사, 근태•연차관리와 연동되어 자동으로 산출되는 급여대장!",
                    text:"이제 급여대장 작성은 웨이브원 시스템에 맡기시고 급여지급시 확인만 해 보세요!<br/>연계된 회계법인 이용 시 세후지급금액 및 퇴직금 산출까지 한눈에!",
                    img:"func_12.png",
                    subList:[]
                }
            ]
        }
    ];

    function handleScroll(){
        let windowScroll = window.scrollY + 120,
            func_0_position = document.querySelectorAll("#func_0")[0].offsetTop,
            func_1_position = document.querySelectorAll("#func_1")[0].offsetTop,
            func_2_position = document.querySelectorAll("#func_2")[0].offsetTop,
            func_3_position = document.querySelectorAll("#func_3")[0].offsetTop,
            func_4_position = document.querySelectorAll("#func_4")[0].offsetTop;

        if(windowScroll >= func_4_position){
            setTap("func_4");
        }else if(windowScroll >= func_3_position){
            setTap("func_3");
        }else if(windowScroll >= func_2_position){
            setTap("func_2");
        }else if(windowScroll >= func_1_position){
            setTap("func_1");
        }else if(windowScroll >= func_0_position){
            setTap("func_0");
        }else{
            setTap(null);
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <ContentsSection addClass="">
            <LogoSection
                addClass="notBg"
                type=""
                text="노무와 세무업무를 한번에 해결!<br/>웨이브원을 사용해서 운영의 효율성을 높여보세요"
            />
            <FixedSection widthPoint={960} boxAddClass="tapFixedBox" addClass="tapFixed" pageBox="contentsSection" headerChk={true}>
                <Tap
                    addClass={""}
                    val={tap}
                    data={[
                        {
                            id:"func_0",
                            category:"워크플로우"
                        },
                        {
                            id:"func_1",
                            category:"직원관리 및 전자계약"
                        },
                        {
                            id:"func_2",
                            category:"근태관리"
                        },
                        {
                            id:"func_3",
                            category:"연차 • 휴가 관리"
                        },
                        {
                            id:"func_4",
                            category:"급여 관리"
                        }
                    ]}
                    func={(id)=>{
                        moveScroll(`#${id}`,120)
                    }}
                />
            </FixedSection>
            <div className="funcPage">
                {funcData && funcData.map((item,i)=>(
                    <FuncSection name={item.name} id={item.id} key={i}>
                        {item.data && item.data.map((subItem,idx)=>(
                            <FuncArea
                                key={idx}
                                addClass={subItem.addclass}
                                title={subItem.title}
                                text={subItem.text}
                                subList={subItem.subList}
                                img={subItem.img}
                            />
                        ))}
                    </FuncSection>
                ))}
            </div>
        </ContentsSection>
    );
};

export default FuncAbout;