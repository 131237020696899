import { bodyScroll_clear, bodyScroll_limit } from "js/function";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function HeaderBtn(data){

    return (
        <>
        {data.btnType == "link" ? 
        <a href={data.btnLink} target="_blank" className={`headerBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></a>
        :
        <button type="button" className={`headerBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></button>
        }
        </>
    );
}

//사이드메뉴 header
function Header(headerData){
    let navigate = useNavigate();
    const { pathname } = useLocation();

    const [menuOpen,setMenuOpen] = useState(false);
    const [menuActive,setMenuActive] = useState(null);
    const [headerActive,setHeaderActive] = useState(true);

    function handleScroll(){
        let scrollTop = window.scrollY;
        if((pathname == "/" || pathname == "/main") && scrollTop <= 0){
            setHeaderActive(false)
        }else{
            setHeaderActive(true)
        }
    }

    useEffect(() => {
        setMenuActive(pathname);

        handleScroll();
        const scrollEv = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(scrollEv);
            window.removeEventListener("scroll", handleScroll);
        };
      }, [pathname]);

    useEffect(() => {
        if(menuOpen){
            bodyScroll_limit();
        }else{
            bodyScroll_clear();
        }
    }, [menuOpen]);

    useEffect(() => {
        handleScroll();
        const scrollEv = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(scrollEv);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
        {pathname == "/" || pathname == "/main" || pathname == "/func" || pathname == "/price" ? 
        <div className={`header ${headerActive||menuOpen ? "active" : ""}`}>
            <div className="headearSubSection">
                <div className="headers_subMenuBox">
                    <HeaderBtn
                        addClass="headerSubBtn"
                        btnType="link"
                        btnLink="https://www.notion.so/wavelabs/WAVE-ONE-5aeb7a165af7427aabf6855378b91fca?pvs=4"
                        contents="고객지원"
                        func={()=>{}}
                    />
                    <HeaderBtn
                        addClass="headerSubBtn"
                        btnType="link"
                        btnLink="https://admin.waveone.team/login"
                        contents="로그인"
                        func={()=>{}}
                    />
                </div>
            </div>
            <div className={`headerSection menuHeaderSection ${headerData.addClass ? headerData.addClass : ""}`}>
                <div className="headerSectionLeft">
                    <button type="button" onClick={()=>{navigate("/")}} className="btnHome">
                        <img src={`/assets/images/${headerActive||menuOpen ? "logo_big_b" : "logo_big_w"}.svg`}/>
                    </button>
                </div>
                <div className="headerMenuBox">
                    <HeaderBtn
                        addClass={(menuActive == "/" || menuActive == "/main") ? "active" : ""}
                        btnType={null}
                        btnLink={null}
                        contents="메인 홈"
                        func={()=>{navigate("/")}}
                    />
                    <HeaderBtn
                        addClass={menuActive == "/func" ? "active" : ""}
                        btnType={null}
                        btnLink={null}
                        contents="기능소개"
                        func={()=>{navigate("/func")}}
                    />
                    <HeaderBtn
                        addClass={menuActive == "/price" ? "active" : ""}
                        btnType={null}
                        btnLink={null}
                        contents="가격정책"
                        func={()=>{navigate("/price")}}
                    />
                    <HeaderBtn
                        btnType="link"
                        btnLink="https://www.notion.so/wavelabs/WAVE-ONE-Blog-3782baa13db4416b97a8ded89ca4b920?pvs=4"
                        contents="블로그"
                        func={()=>{}}
                    />
                    <HeaderBtn
                        addClass="lineBtn"
                        btnType="link"
                        btnLink="https://admin.waveone.team/request"
                        contents="무료체험 신청"
                        func={()=>{}}
                    />
                </div>
                <button type="button" onClick={()=>setMenuOpen(!menuOpen)} className={`btnMenuOpen ${menuOpen ? "active" : ""}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <div className={`sideMenu ${menuOpen ? "active" : ""}`}>
                <div className="sideMenu_section">
                    <HeaderBtn
                        addClass={(menuActive == "/" || menuActive == "/main") ? "active sideMenuBtn" : "sideMenuBtn"}
                        btnType={null}
                        btnLink={null}
                        contents="메인 홈"
                        func={()=>{setMenuOpen(false);navigate("/")}}
                    />
                    <HeaderBtn
                        addClass={menuActive == "/func" ? "active sideMenuBtn" : "sideMenuBtn"}
                        btnType={null}
                        btnLink={null}
                        contents="기능소개"
                        func={()=>{setMenuOpen(false);navigate("/func")}}
                    />
                    <HeaderBtn
                        addClass={menuActive == "/price" ? "active sideMenuBtn" : "sideMenuBtn"}
                        btnType={null}
                        btnLink={null}
                        contents="가격정책"
                        func={()=>{setMenuOpen(false);navigate("/price")}}
                    />
                    <HeaderBtn
                        addClass="sideMenuBtn"
                        btnType="link"
                        btnLink="https://www.notion.so/wavelabs/WAVE-ONE-Blog-3782baa13db4416b97a8ded89ca4b920?pvs=4"
                        contents="블로그"
                        func={()=>{setMenuOpen(false)}}
                    />
                    <HeaderBtn
                        addClass="sideMenuBtn"
                        btnType="link"
                        btnLink="https://www.notion.so/wavelabs/WAVE-ONE-5aeb7a165af7427aabf6855378b91fca?pvs=4"
                        contents="고객지원"
                        func={()=>{setMenuOpen(false)}}
                    />
                    <HeaderBtn
                        addClass="sideMenuBtn"
                        btnType="link"
                        btnLink="https://admin.waveone.team/login"
                        contents="로그인"
                        func={()=>{setMenuOpen(false)}}
                    />
                </div>
            </div>
        </div>
        :""}
        </>
    );
}

export {Header};