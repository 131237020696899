import { PageErrBox } from "component/app/pageErr";
import { BtnBox, BtnItem } from "component/basic/btns";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const PublicOnly = () => {
    const navigate = useNavigate();
    const param = useParams();
    const errNumber = param.err;

    useEffect(() => {
        if(!localStorage.getItem("token") && window.location.pathname !== "/pageErr"){
            // navigate(0);
        }
    }, []);

    return (
        <>
            <PageErrBox
                icon={false}
                title={errNumber ? `${errNumber} Error` : "서비스 이용에 불편을 드려 죄송합니다."}
                text={errNumber ? "서비스 이용에 불편을 드려 죄송합니다.<br/>서비스 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요" : "서비스 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요"}
            />
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"처음으로"}
                    disabled={false}
                    func={() => {navigate("/")}}
                />
            </BtnBox>
        </>
    );
};

export default PublicOnly;