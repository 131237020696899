import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { LogoSection, PriceArea, PriceInfoArea, PriceInfoAreaBox } from "component/page/item";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from 'swiper/modules';
import { Tap } from "component/basic/tap";
import { moveScroll } from "js/function";
import FixedSection from "component/basic/fixedBox";

const Price = (props) => {
    const navigate = useNavigate();
    const [tap,setTap] =  useState(null);
   
    const priceData = [
        {
            addClass:"",
            name:"Basic",
            subName:"기본 서비스",
            price:"9900",
            subList:[
                "홈피드 전자결재 시스템",
                "게시판",
                "직원 관리",
                "근태 관리",
                "추가수당 관리",
                "연차 관리",
                "문서관리",
                "급여 관리"
            ]
        },
        {
            addClass:"",
            name:"Premium",
            subName:"프리미엄 서비스",
            price:"15000",
            subList:[
                "Basic의 모든 기능",
                "급여대장 자동작성",
                "급여명세서 자동발송",
                "퇴직자 실 퇴직금 정보 관리",
                "제휴 회계법인과 연계된 원스톱 노무 및 세무 관리"
            ]
        }
    ];

    const priceSubData_left = [
        {
            id:"priceSub_0",
            addClass:"",
            img:"func_0.svg",
            title:"홈 피드 및 게시판",
            text:"우리 병원의 필요한 업무를 확인하고 쉽게 처리할 수 있어요!",
            subList:[
                {
                    title:"워크플로우를 통한 간편한 승인 절차",
                    text:"전자결재가 필요한 항목을 한 번에 확인하고 손쉽게 완료",  
                },
                {
                    title:"지시사항 확인 및 진행율 체크",
                    text:'날짜별, 직원별 "할 일"을 지정하고 알림 발송 가능',  
                },
                {
                    title:"당일 근무현황 확인",
                    text:"근무중인 직원, 휴무, 휴가중인 직원 리스트를 한 눈에!",  
                },
                {
                    title:"1년차 미만 근무자 확인",
                    text:null,  
                }
            ]
        },
        {
            id:"priceSub_1",
            addClass:"",
            img:"func_1.svg",
            title:"근태 관리",
            text:"복잡한 근태/스케줄 관리, AI 스케줄러로 도움을 받아보세요!",
            subList:[
                {
                    title:"필요 직원 수 입력 시 AI 스케줄러 주간 스케줄 자동 생성",
                    text:"불편한 근무스케줄 관리에서 벗어나 프로그램 간편 작성",  
                },
                {
                    title:"모바일 앱을 통한 출퇴근 관리",
                    text:"병원 WIFI를 기반으로 모바일 출퇴근 등록 가능",  
                },
                {
                    title:"지각이 잦은 직원에게 패널티 설정 기능",
                    text:"모바일 출퇴근 기록으로 직원 근무시간 확인으로 원하는 시간만큼 지각 패널티 설정으로 급여 내 반영",  
                }
            ]
        },
        {
            id:"priceSub_2",
            addClass:"",
            img:"func_2.svg",
            title:"연차 관리",
            text:"근로기준법에 맞는 연차를 자동으로 부여, 소멸, 관리해줘요!",
            subList:[
                {
                    title:"연차 부여와 소멸의 자동화",
                    text:"정해진 기준에 맞게 각 직원별로 연차가 자동 부여/관리",  
                },
                {
                    title:"번거로운 연차촉진제도 또한 자동화",
                    text:"연차촉진제도 설정시 촉진안내 및 관리가 자동으로",  
                },
                {
                    title:"모바일 앱을 통해 편리하게 연차 신청/승인 가능",
                    text:null,  
                },
                {
                    title:"미사용 연차 수당은 급여대장에 자동 반영",
                    text:null,  
                }
            ]
        },
        {
            id:"priceSub_3",
            addClass:"",
            img:"func_3.svg",
            title:"추가수당 관리",
            text:"우리 병원에 맞는 수당 양식을 직접 만들어 사용할 수 있어요!",
            subList:[
                {
                    title:"시간 단위, 일 단위 수당 지급 가능",
                    text:"지급액과 지급기준을 자유롭게 설정해 우리 병원만의 추가수당 만들기",  
                },
                {
                    title:"모바일 앱을 통해 편리하게 수당지급 신청/승인 가능",
                    text:null,  
                },
                {
                    title:"승인된 추가수당은 급여대장에 자동 반영",
                    text:null,  
                }
            ]
        }
    ];

    const priceSubData_right = [
        {
            id:"priceSub_4",
            addClass:"",
            img:"func_4.svg",
            title:"문서 관리",
            text:"번거로운 문서발급 신청과 승인을 웨이브원에서 간단하게!",
            subList:[
                {
                    title:"직원들이 앱으로 간편한 문서발급 신청",
                    text:"재직증명서 등 발급요청이 빈번한 문서를 번거로운 절차없이 웨이브원으로 손 쉽게 신청/발급 가능",  
                },
                {
                    title:"신청된 문서의 간편한 승인 및 이력관리",
                    text:"신청된 문서를 한 눈에 확인하고 승인할 수 있으며 신청 및 승인 이력도 체계적으로 관리 가능",  
                },
                {
                    title:"회사에서 필요한 각종 문서 양식 제공",
                    text:null,  
                }
            ]
        },
        {
            id:"priceSub_5",
            addClass:"",
            img:"func_5.svg",
            title:"급여 관리",
            text:"등록된 데이터를 기반한 직원 별 급여액 자동 산출",
            subList:[
                {
                    title:"매 월 지급되어야 하는 급여액 자동 산출",
                    text:"기본급, 추가수당, 휴일수당, 지각, 결근 등 모든 데이터를 기반으로 정확한 급여액 계산 가능",  
                },
                {
                    title:"개정된 세법을 정확히 반영한 급여지급액 산출",
                    text:"4대보험, 소득세 등 모두 반영한 정확한 급여지급액 반영",  
                },
                {
                    title:"급여명세서 발송",
                    text:"근로기존법에 맞는 급여명세서를 클릭 한번에 자동 발송 모든 직원이 모바일 앱으로 편리하게 급여명세서 조회",  
                }
            ]
        },
        {
            id:"priceSub_6",
            addClass:"",
            img:"func_6.svg",
            title:"직원 관리",
            text:"직원 입퇴사 관리부터 근로계약서 작성까지 한 번에!",
            subList:[
                {
                    title:"간편하고 정확한 근로계약서 작성",
                    text:"간단한 근로정보와 급여만 입력하면 근로기준법에 맞는 근로계약서 자동 작성",  
                },
                {
                    title:"병원 운영시간에 맞는 최적의 포괄근로/임금계약서 작성",
                    text:"하루 8시간 이상 근무하는 직원의 연장 근로시간까지 모두 빈틈없는 포괄근로/임금계약서를 한 번에 작성",  
                },
                {
                    title:"앱을 통한 효율적인 전자서명",
                    text:"작성된 근로계약서와 임금계약서를 모바일 앱으로 전자서명 가능",  
                },
                {
                    title:"계약직 직원의 재계약 알림",
                    text:"근로계약을 공백없이 관리할 수 있는 재계약 알림",  
                },
                {
                    title:"반복되는 근로계약 양식을 템플릿으로 만들어 관리 가능",
                    text:null,  
                }
            ]
        }
    ];

    function handleScroll(){
        let windowScroll = window.scrollY + 120,
            func_0_position = document.querySelectorAll("#priceSub_0")[0].offsetTop,
            func_1_position = document.querySelectorAll("#priceSub_1")[0].offsetTop,
            func_2_position = document.querySelectorAll("#priceSub_2")[0].offsetTop,
            func_3_position = document.querySelectorAll("#priceSub_3")[0].offsetTop,
            func_4_position = document.querySelectorAll("#priceSub_4")[0].offsetTop,
            func_5_position = document.querySelectorAll("#priceSub_5")[0].offsetTop,
            func_6_position = document.querySelectorAll("#priceSub_6")[0].offsetTop;

        if(windowScroll >= func_6_position){
            setTap("priceSub_6");
        }else if(windowScroll >= func_5_position){
            setTap("priceSub_5");
        }else if(windowScroll >= func_4_position){
            setTap("priceSub_4");
        }else if(windowScroll >= func_3_position){
            setTap("priceSub_3");
        }else if(windowScroll >= func_2_position){
            setTap("priceSub_2");
        }else if(windowScroll >= func_1_position){
            setTap("priceSub_1");
        }else if(windowScroll >= func_0_position){
            setTap("priceSub_0");
        }else{
            setTap(null);
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <ContentsSection addClass="">
            <LogoSection
                addClass=""
                type="notImg"
                title="WAVEONE을 무료로 이용해보세요"
                text="노무와 세무업무를 한번에 해결!<br/>웨이브원을 사용해서 운영의 효율성을 높여보세요 🤗"
            >
                <div className="priceDataArea">
                    {priceData && priceData.map((item,i)=>(
                        <PriceArea
                            addClass={item.addClass}
                            name={item.name}
                            subName={item.subName}
                            price={item.price}
                            subList={item.subList}
                        />
                    ))}
                </div>
                <div className="priceDataAreaSlide">
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        loop={true}
                        onRealIndexChange={(e) => {}}
                        autoplay={{delay: 3000, disableOnInteraction: false}}
                        pagination={{
                            type: "progressbar",
                        }}
                        modules={[Autoplay,Pagination]}
                    >
                        {priceData && priceData.map((item,i)=>(
                            <SwiperSlide key={i}>
                                <PriceArea
                                    addClass={item.addClass}
                                    name={item.name}
                                    subName={item.subName}
                                    price={item.price}
                                    subList={item.subList}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <p className="priceCaption">모든 서비스 요금은 부가세(10%)가 별도 부과됩니다.</p>
            </LogoSection>
            <h1 className="funcPage_title">WAVEONE 상세 기능</h1>
            <FixedSection widthPoint={960} boxAddClass="tapFixedBox" addClass="tapFixed" pageBox="contentsSection" headerChk={true}>
                <Tap
                    addClass={""}
                    val={tap}
                    data={[
                        {
                            id:"priceSub_0",
                            category:"홈 피드 및 게시판"
                        },
                        {
                            id:"priceSub_1",
                            category:"근태 관리"
                        },
                        {
                            id:"priceSub_2",
                            category:"연차 관리"
                        },
                        {
                            id:"priceSub_3",
                            category:"추가수당 관리"
                        },
                        {
                            id:"priceSub_4",
                            category:"문서 관리"
                        },
                        {
                            id:"priceSub_5",
                            category:"급여 관리"
                        },
                        {
                            id:"priceSub_6",
                            category:"직원 관리"
                        }
                    ]}
                    func={(id)=>{
                        moveScroll(`#${id}`,120)
                    }}
                />
            </FixedSection>
            <div className="pricePage">
                <PriceInfoAreaBox>
                    {priceSubData_left && priceSubData_left.map((item,i)=>(
                        <PriceInfoArea
                            id={item.id}
                            img={item.img}
                            addClass={item.addClass}
                            title={item.title}
                            text={item.text}
                            subList={item.subList}
                        />
                    ))}
                </PriceInfoAreaBox>
                <PriceInfoAreaBox>
                    {priceSubData_right && priceSubData_right.map((item,i)=>(
                        <PriceInfoArea
                            id={item.id}
                            img={item.img}
                            addClass={item.addClass}
                            title={item.title}
                            text={item.text}
                            subList={item.subList}
                        />
                    ))}
                </PriceInfoAreaBox>
            </div>
        </ContentsSection>
    );
};

export default Price;