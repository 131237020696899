function PageSizing(data){
    return (
        <div className={`pageSizing ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ContentsSection(data){
    return (
        <div className={`contentsSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ItemSection(data){
    return (
        <div className={`itemSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ItemSectionTitle(data){
    return (
        <div className="itemSectionTitleBox">
            <h1 className="itemSectionTitle" dangerouslySetInnerHTML={{__html:data.contents}}/>
            {data.subTitle ? <p className="itemSectionSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
        </div>
    );
}

function TextItem(data){
    return (
        <div className={`textItem ${data.addClass ? data.addClass : ""}`}>
            <p className="textItem_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="textItem_contents" dangerouslySetInnerHTML={{__html:data.contents}}/>
        </div>
    );
}

function PageTitle(data){
    return (
        <div className="pageTitleBox">
            <h1 className="pageTitle" dangerouslySetInnerHTML={{__html:data.contents}}/>
            {data.subTitle ? <p className="pageSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
            {data.subTitleBtn ? <button type="button" onClick={()=>{data.subTitleBtnFunc()}} className="pageSubTitleBtn" dangerouslySetInnerHTML={{__html:data.subTitleBtn}}/> : ""}
        </div>
    );
}

function InputSection(data){
    return (
        <div className={`input_section ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function LogoBox(data){
    return (
        <div className={`logoBox ${data.addClass ? data.addClass : ""}`}>
            <img src="/assets/images/app/logo.svg"/>
        </div>
    );
}

function InfoItem(data){
    return (
        <p className={`infoItem ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function CaptionItem(data){
    return (
        <p className={`captionItem ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function TimeSelBox(data){
    return (
        <div className={`timeSelBox ${data.addClass||""}`}>
            {data?.data?.map((item,i)=>(
                <button type="button" onClick={()=>{data.func(item.time)}} key={i} className={`timeSelItem ${data.value == item.time ? "active" : ""}`} disabled={item.is_reservation == 1 ? true : false}>{item.time}</button>
            ))}
        </div>
    );
}

function EditerBox(data){

    return (
        <div className="editerBox">
            {data.children}
        </div>
    );
}

function InfoTextBox(data){

    return (
        <div className="infoTextBox">
            <h1 className="infoText_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="infoText" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function InputViewBox(data){

    return (
        <div className={`inputViewBox ${data.type == "textArea" ? "textAreaViewBox" : ""}`}>
            <p className="inputViewText" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function FileViewBox(data){

    return (
        <div className={`fileViewBox`}>
            {data.data.map((item,i)=>(
                <div className="backgroundImg" key={i} style={{backgroundImage:`url('${item.image_url}')`}}/>
            ))}
        </div>
    );
}

function NameTextItemBox(data){

    return (
        <div className={`nameTextItemBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function NameTextItem(data){

    return (
        <div className={`nameTextItem ${data.addClass||""}`}>
            <p className="nameTextItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="nameTextItem_text" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function LinkItem(data){

    return (
        <div className={`linkItemBox ${data.addClass||""}`}>
            {data.data && data.data.map((item,i)=>(
                <button type="button" key={i} className="linkItem" onClick={()=>{item.func()}}>
                    {item.icon ? <img src={item.icon}/> : ""}
                    <p className="linkItem_name" dangerouslySetInnerHTML={{__html:item.name}}/>
                </button>
            ))}
        </div>
    );
}

export {PageSizing,ContentsSection,ItemSection,ItemSectionTitle,TextItem,LogoBox,PageTitle,InputSection,InfoItem,CaptionItem,TimeSelBox,EditerBox,InfoTextBox,InputViewBox,FileViewBox,NameTextItemBox,NameTextItem,LinkItem};