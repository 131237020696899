import Main from "pages/main/Main";
import PublicOnly from "pages/private/publicOnly";
import FuncAbout from "pages/sub/Func";
import Price from "pages/sub/Price";
import React from "react";
import { Navigate } from "react-router-dom";

export const routes = [
    {
        exact: true,
        path: "/pageErr",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/pageErr/:err",
        component: <PublicOnly/>,
        type : null
    },
    {
        exact: true,
        path: "/",
        component: <Navigate to="/main" />,
        type : null
    },
    {
        exact: true,
        path: "/main",
        component: <Main/>,
        type : null
    },
    {
        exact: true,
        path: "/func",
        component: <FuncAbout/>,
        type : null
    },
    {
        exact: true,
        path: "/price",
        component: <Price/>,
        type : null
    },
];