//tap item
function Tap(data){
    return (
        <div className={`tapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`tapItem ${item.id == data.val ? "active" : ""}`}>{item.category}</button>
            ))}
        </div>
    );
}

function SubTap(data){
    return (
        <div className={`subTapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`subTapItem ${item.id == data.val ? "active" : ""}`}>{item.category}</button>
            ))}
        </div>
    );
}

function SubLine(data){
    return (
        <div className={`subLine ${data.addClass ? data.addClass : ""}`} style={{margin:data.margin}}></div>
    );
}

function PopupTap(data){
    return (
        <div className={`popupTapBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`popupTapItem ${item.id == data.val ? "active" : ""}`}>{item.category}</button>
            ))}
        </div>
    );
}

export {Tap, SubTap, SubLine, PopupTap}