import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection } from "component/app/items";
import { AppBtnBox, LogoSection, MainAboutItem, MainAppAboutTextItem, MainPartnerItem, MainSection, MainSectionTitle } from "component/page/item";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from 'swiper/modules';

const Main = (props) => {
    const navigate = useNavigate();
    const partnerData = ["partner_0.png","partner_1.png","partner_2.png","partner_3.png","partner_4.png","partner_5.png"];

    return (
        <ContentsSection addClass="">
            <LogoSection
                type="wType"
                text="노무와 세무업무를 한번에 해결!<br/>웨이브원을 사용해서 운영의 효율성을 높여보세요"
            >
                <div className="main_topImgBox">
                    <img src="/assets/images/main_1.png"/>
                </div>
            </LogoSection>
            <MainSection>
                <MainSectionTitle
                    title={"WAVEONE으로 노무와 세무 업무를 한번에!"}
                    text={"웨이브원은 회사가 성장에 집중할 수 있도록,<br/>회사의 운영 효율성을 높이기 위해 최적화된 올인원 플랫폼 입니다."}
                    captionData={["직원관리","전자계약","근태관리","연차관리","수당관리","문서관리","급여관리"]}
                />
                <div className="mainAboutItem_area">
                    <MainAboutItem
                        icon={"main_icon_0.svg"}
                        title={"업무 자동화"}
                        text={"웨이브원은 반복적인 업무를 단순화 및 자동화 하여 효율적인 업무를 가능하게 합니다."}
                    />
                    <MainAboutItem
                        icon={"main_icon_1.svg"}
                        title={"전자 계약 및 문서"}
                        text={"웨이브원의 모든 문서는 전자화 되어있어 보다 안전하고 정확하게 관리할 수 있습니다."}
                    />
                    <MainAboutItem
                        icon={"main_icon_2.svg"}
                        title={"사용자 중심의 플랫폼"}
                        text={"웨이브원은 노무 • 세무 업무에 최적화된 플랫폼으로 다양한 이슈에 대응할 수 있도록 실시간으로 업데이트 됩니다."}
                    />
                </div>
                <div className="mainAboutItem_slideArea">
                    <Swiper
                        spaceBetween={70}
                        slidesPerView={2}
                        centeredSlides={true}
                        pagination={{
                            type: "progressbar",
                        }}
                        loop={true}
                        onRealIndexChange={(e) => {}}
                        modules={[Pagination]}
                    >
                        <SwiperSlide>
                            <MainAboutItem
                                icon={"main_icon_0.svg"}
                                title={"업무 자동화"}
                                text={"웨이브원은 반복적인 업무를 단순화 및 자동화 하여 효율적인 업무를 가능하게 합니다."}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <MainAboutItem
                                icon={"main_icon_1.svg"}
                                title={"전자 계약 및 문서"}
                                text={"웨이브원의 모든 문서는 전자화 되어있어 보다 안전하고 정확하게 관리할 수 있습니다."}
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <MainAboutItem
                                icon={"main_icon_2.svg"}
                                title={"사용자 중심의 플랫폼"}
                                text={"웨이브원은 노무 • 세무 업무에 최적화된 플랫폼으로 다양한 이슈에 대응할 수 있도록 실시간으로 업데이트 됩니다."}
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </MainSection>
            <MainSection addClass="fullPage">
                <div className="mainAppAbout">
                    <div className="mainAppAbout_textBox">
                        <p className="mainAppAbout_subTitle">직원용 어플리케이션</p>
                        <h2 className="mainAppAbout_title">WAVEONE</h2>
                        <p className="mainAppAbout_text">근무/연차/급여 내역 등 근로와 관련된 모든 정보를 올인원 솔루션 WAVEONE을 통해 간편하게 확인하세요</p>
                        <div className="mainAppAbout_InfoBox">
                            <MainAppAboutTextItem
                                icon="appInfo_icon_0.png"
                                title="정확하고 편리한"
                                text="출퇴근 관리"
                            />
                            <MainAppAboutTextItem
                                icon="appInfo_icon_1.png"
                                title="내 급여의 상세 내역을 한눈에"
                                text="급여 관리"
                            />
                            <MainAppAboutTextItem
                                icon="appInfo_icon_2.png"
                                title="신청과 사용 이력까지 간편한"
                                text="연차 관리"
                            />
                            <MainAppAboutTextItem
                                icon="appInfo_icon_3.png"
                                title="실시간 확인 가능한"
                                text="스케쥴 관리"
                            />
                        </div>
                        <AppBtnBox/>
                    </div>
                    <div className="mainAppAbout_imgBox">
                        <img src="/assets/images/main_2.png"/>
                    </div>
                </div>
            </MainSection>
            <MainSection>
                <MainSectionTitle
                    title={"웨이브원은 고객사의 성장과 함께합니다."}
                    text={null}
                    captionData={null}
                />
                <div className="mainPartnerItemBox">
                    {partnerData.map((item,i)=>(
                        <MainPartnerItem key={i} img={item}/>
                    ))}
                </div>
                <div className="mainPartner_slideArea">
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={2}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{delay: 3000, disableOnInteraction: false}}
                        onRealIndexChange={(e) => {}}
                        modules={[Autoplay]}
                    >
                        {partnerData.map((item,i)=>(
                            <SwiperSlide key={i}>
                                <MainPartnerItem img={item}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </MainSection>
        </ContentsSection>
    );
};

export default Main;