import { PageErrBox } from "component/app/pageErr";
import { BtnBox, BtnItem } from "component/basic/btns";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Private = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem("token")){
            navigate(0);
        }
    }, []);

    return (
        <>
            <PageErrBox
                icon={false}
                title="서비스 이용에 불편을 드려 죄송합니다."
                text="서비스 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요"
            />
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"처음으로"}
                    disabled={false}
                    func={() => {navigate("/")}}
                />
            </BtnBox>
        </>
    );
};

export default Private;