import { getStyle } from "js/function";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

export default function FixedSection(data){
    const fixedTargetRef = useRef(null);
    const fixedBoxRef = useRef(null);
    const [fixedCategory, setFixedCategory] = useState("");

    function handleScroll(){
        if(data.headerChk && data.footerChk){
            let header = window.innerWidth <= data.widthPoint && document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0,
                footer = window.innerWidth <= data.widthPoint && document.querySelectorAll(".footer")[0] ? document.querySelectorAll(".footer")[0].offsetTop : null,
                targetHeight = fixedTargetRef?.current?.clientHeight,
                pageBox = getStyle(document.querySelectorAll(`.${data.pageBox}`)[0], "paddingBottom","number");
            if(footer && window.scrollY + header + targetHeight > footer - Number(pageBox)){
                setFixedCategory("hdFtFixedItem");
            }else if(window.scrollY + header > fixedBoxRef?.current?.offsetTop){
                setFixedCategory("headerFixedItem");
            }else{
                setFixedCategory("");
            }
        }else if(data.headerChk){
            let header = window.innerWidth <= data.widthPoint && document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0;
            if(window.scrollY + header > fixedBoxRef?.current?.offsetTop){
                setFixedCategory("headerFixedItem");
            }else{
                setFixedCategory("");
            }
        }else{
            if(window.scrollY > fixedBoxRef?.current?.offsetTop){
                setFixedCategory("fixedItem");
            }else{
                setFixedCategory("");
            }
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`fixedPoint ${data.boxAddClass ? data.boxAddClass : ""}`} ref={fixedBoxRef}>
            <div className={`fixedSection ${data.addClass ? data.addClass : ""} ${fixedCategory}`} ref={fixedTargetRef}>{data.children}</div>
        </div>
    );
}