import Private from "pages/private/Private";
import PublicOnly from "pages/private/publicOnly";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "component/basic/scrollTopSet";
import { BottomErrMsg, LoadingBox, Popup } from "component/basic/popup";
import { ErrorBoundary } from "react-error-boundary";
import { routes } from "./routes";
import { PageSizing } from "component/app/items";
import { Header } from "component/elements/header";
import { Footer } from "component/elements/footer";
import { ChannelTalkLoad } from "component/app/channelTalk";

const Router = () => {
    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);

    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    window.errPopupOpen = (data) => {
        if(data.data || data.alert){
            setPopupData({
                addClass:null,
                title:data.alert||"",
                text:data.data||"",
                closeType:true,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{},
                btn0:"확인",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        }
    }

    window.errMsg = (msg) => {
        if(msg){
            setBottomMsgData({
                text : msg,
                chk : bottomMsgData.chk + 1
            });
        }
    }

    window.loadingBox = (type) => {
        setLoadingBoxOpen(type);
    }

    useEffect(()=>{
        
    },[]);

    //백화 시 노출
    const ErrorFallback = (err) => {
        console.log(err)
        return (
            <div className="pageSizing">
                <div className="pageErrBox">
                    <h1 className="pageErrTitle">서비스 이용에 불편을 드려 죄송합니다.</h1>
                    <p className="pageErrText">서비스 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요</p>
                </div>
                <div className="pageBtn_box">
                    <button type="button" className="pageBtn" onClick={() => window.location.href = "/"}>처음으로</button>
                </div>
            </div>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <ScrollToTop/>
                <Header/>
                <PageSizing>
                <Routes>
                    {routes.map(
                        ({ exact, path, component, type }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={type == "login" ? 
                                    token ? component : <Private/>
                                : type == "notLogin" ?  
                                    token ? <PublicOnly/> : component
                                : component}
                            />
                        )
                    )}

                    {/*404*/}
                    <Route
                        key={`public-route-/*`}
                        exact={true}
                        path="/*"
                        element={<PublicOnly/>}
                    />
                </Routes>
                </PageSizing>
                <Footer/>
                {/* 채널톡 연동 */}
                <ChannelTalkLoad/>
                <Popup
                    data={popupData}
                />
                <BottomErrMsg
                    text={bottomMsgData.text ? bottomMsgData.text : ""}
                    chk={bottomMsgData.chk}
                />
                <LoadingBox openType={loadingBoxOpen}/>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default Router;