import { ChannelShowMessenger } from "component/app/channelTalk";
import { BtnBox, BtnItem } from "component/basic/btns";
import { comFormat } from "js/function";
import { useNavigate } from "react-router-dom";

function MainSection(data){
    return (
        <div className={`mainSection ${data.addClass ? data.addClass : ""}`} style={{backgroundImage:`url('/assets/images/${data.bgImg}')`}}>
            {data.children}
        </div>
    );
}

function LogoSection(data){
    const navigate = useNavigate();

    return (
        <div className={`logoSection ${data.addClass ? data.addClass : ""} ${data.type ? data.type : ""}`}>
            {data.type !== "notImg" ? <img src={`/assets/images/${data.type == "wType" ? "logo_big_w.svg" : "logo_big_b.svg"}`}/> : ""}
            {data.title ? <h1 className="logoSection_title" dangerouslySetInnerHTML={{__html:data.title}}/> : ""}
            <p className="logoSection_text" dangerouslySetInnerHTML={{__html:data.text}}/>
            <BtnBox>
                <BtnItem
                    addClass="logoSectionBtn logoSectionBtn_big"
                    func={()=>{
                        //채널톡 활성화
                        ChannelShowMessenger();
                    }}
                    contents={`<img src="/assets/images/${data.type == "wType" ? "cs_icon.svg" : "cs_icon_w.svg"}"/> 도입 문의하기`}
                />
                <BtnItem
                    addClass="logoSectionBtn line"
                    func={()=>{navigate("/func")}}
                    contents="기능 소개"
                />
            </BtnBox>
            {data.children}
        </div>
    );
}

function MainSectionCaption(data){

    return (
        <div className={`mainSectionCaptionBox ${data.addClass ? data.addClass : ""}`}>
            {data.data && data.data.map((item,i)=>(
                <p className="mainSectionCaption" key={i} dangerouslySetInnerHTML={{__html:item}}/>
            ))}
        </div>
    );
} 

function MainAboutItem(data){

    return (
        <div className={`mainAboutItem ${data.addClass ? data.addClass : ""}`}>
            {data.icon ? <div className="mainAboutItem_img"><img src={`/assets/images/${data.icon}`}/></div> : ""}
            <h2 className="mainAboutItem_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="mainAboutItem_text" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
} 

function MainSectionTitle(data){

    return (
        <div className={`mainSectionTitleBox ${data.addClass ? data.addClass : ""}`}>
            <h2 className="mainSectionTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.text ? <p className="mainSectionText" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
            {data.captionData ? 
            <MainSectionCaption
                data={data.captionData}
            />
            :""}
        </div>
    );
} 

function MainPartnerItem(data){

    return (
        <div className={`mainPartnerItem ${data.addClass ? data.addClass : ""}`} style={{backgroundImage:`url('/assets/images/${data.img}')`}}/>
    );
} 

function MainAppAboutTextItem(data){

    return (
        <div className={`mainAppAboutTextItem ${data.addClass ? data.addClass : ""}`}>
            {data.icon ? <img src={`/assets/images/${data.icon}`}/> : ""}
            <h2 className="mainAppAboutTextItem_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="mainAppAboutTextItem_text" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function AppBtnBox(data){
    return (
        <div className={`appBtnBox ${data.addClass ? data.addClass : ""}`}>
            <AppBtn
                addClass=""
                url=""//미비사항 : 앱링크(애플)
                contents="<img src='/assets/images/apple.png'/>"
            />
            <AppBtn
                addClass=""
                url=""//미비사항 : 앱링크(구글)
                contents="<img src='/assets/images/google.png'/>"
            />
        </div>
    );
}

function AppBtn(data){
    return (
        <a target="_blank" href={data.url} className={`appBtn ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function FuncSection(data){
    return (
        <div className={`funcSection ${data.addClass ? data.addClass : ""}`} id={data.id ? data.id : ""}>
            <p className="funcSection_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            {data.children}
        </div>
    );
}

function FuncArea(data){
    return (//reverse
        <div className={`funcArea ${data.addClass ? data.addClass : ""}`}>
            <div className="funcArea_textBox">
                <h2 className="funcArea_title" dangerouslySetInnerHTML={{__html:data.title}}/>
                {data.text ? <p className="funcArea_text" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
                {data.subList && data.subList.map((item,i)=>(
                    <div className="funcArea_subList" key={i}>
                        <img src={`/assets/images/chk_1.svg`}/>
                        <p className="funcArea_subList_text" dangerouslySetInnerHTML={{__html:item}}/>
                    </div>
                ))}
            </div>
            {data.img ? 
            <div className="funcArea_imgBox">
                <img src={`/assets/images/${data.img}`}/>
            </div>
            :""}
        </div>
    );
}

function PriceArea(data){
    return (
        <div className={`priceArea ${data.addClass ? data.addClass : ""} ${data.name === "Basic" ? "bColor" : ""}`}>
            <div className="priceDataBox">
                <h3 className="priceData_name" dangerouslySetInnerHTML={{__html:data.name}}/>
                <p className="priceData_subName" dangerouslySetInnerHTML={{__html:data.subName}}/>
                <div className="priceData_priceBox">
                    <p className="priceData_priceCaption">1인당</p>
                    <p className="priceData_price"><span dangerouslySetInnerHTML={{__html:`${comFormat(data.price)}원`}}/>/월</p>
                </div>
            </div>
            <div className="priceSubDataList">
                {data.subList && data.subList.map((item,i)=>(
                    <div className={`priceSubDataItem ${item === "Basic의 모든 기능" ? "priceSubData_func" : ""}`} key={i}>
                        <img src={`/assets/images/${data.name === "Basic" ? "chk_0" : "chk_1"}.svg`}/>
                        <p className="priceSubDataItem_text" dangerouslySetInnerHTML={{__html:item}}/>
                    </div>
                ))}
            </div>
        </div>
    );
}

function PriceInfoAreaBox(data){
    return (
        <div className={`priceInfoAreaBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function PriceInfoArea(data){
    return (
        <div className={`priceInfoArea ${data.addClass ? data.addClass : ""}`} id={data.id ? data.id : ""}>
            <div className="priceInfoBox">
                {data.img ? <div className="priceInfoImgBox"><img src={`/assets/images/${data.img}`}/></div> :""}
                <div className="priceInfo_textBox">
                    <h2 className="priceInfoBox_title" dangerouslySetInnerHTML={{__html:data.title}}/>
                    <p className="priceInfoBox_text" dangerouslySetInnerHTML={{__html:data.text}}/>
                </div>
            </div>
            <div className="priceInfo_list">
                {data.subList && data.subList.map((item,i)=>(
                    <div className="priceInfo_listItem" key={i}>
                        <h3 className="priceInfo_listItem_title" dangerouslySetInnerHTML={{__html:item.title}}/>
                        <p className="priceInfo_listItem_text" dangerouslySetInnerHTML={{__html:item.text}}/>
                    </div>
                ))}
            </div>
        </div>
    );
}

export {MainSection,LogoSection,MainSectionCaption,MainAboutItem,MainSectionTitle,MainPartnerItem,MainAppAboutTextItem,AppBtnBox,FuncSection,FuncArea,PriceInfoAreaBox,PriceArea,PriceInfoArea};